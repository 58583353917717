/**
 * jQuery Line Progressbar
 * Author: KingRayhan<rayhan095@gmail.com>
 * Author URL: http://rayhan.info
 * Version: 1.0.0
 */

(function ($) {
	'use strict';

	$.fn.LineProgressbar = function (options) {
		var options = $.extend(
			{
				percentage: null,
				showCount: true,
				duration: 1000,
				// Styling Options
				cssClass: 'progress-bar-striped',
				height: '10px',
				width: '0%',
			},
			options
		);

		return this.each(function (index, el) {
			// Markup
			$(el).html(
				'<div class="progress-stick">' +
					'<div class="progress text-center">' +
					'<div class="progress-bar ' +
					options.cssClass +
					' active" role="progressbar"></div>' +
					'</div>' +
					'<div class="percent-count"></div>' +
					'</div>'
			);

			var progressFill = $(el).find('.progress');
			var progressBar = $(el).find('.progress-bar');

			progressFill.css({
				height: options.height,
			});
			progressBar.css({
				width: options.width,
				// backgroundColor: options.backgroundColor,
				// borderRadius: options.radius,
			});

			// Progressing
			progressBar.animate(
				{
					width: options.percentage + '%',
				},
				{
					step: function (x) {
						if (options.showCount) {
							$(el)
								.find('.percent-count')
								.text(Math.round(x) + '% Complete');
						}
					},
					duration: options.duration,
				}
			);
		});
	};
})(jQuery);
